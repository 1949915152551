import React, { Component } from 'react';
import { Link } from 'gatsby'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

import { withFirebase } from '../Firebase';

const Paperform = loadable(() => pMinDelay(import('../../components/Paperform'), 1000), {
  fallback: <div className='text-center italic text-2xl font-extrabold text-gray-500'>Loading form...</div>,
})

class ComItemNewHire extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      form: this.props.form,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.props.firebase
      .form(this.props.form)
      .on('value', snapshot => {
        this.setState({
          form: snapshot.val(),
        });
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.form().off();
  }

  render() {
    const { form } = this.state;

    return (
      <>
        <header className="bg-white shadow">
          <div className="flex sm:flex-col items-center max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl sm:text-xl sm:text-center font-bold leading-tight text-gray-900">
              New Employee Request Form
            </h1>
            <Link to={`/${form.id}`} className='block ml-6 sm:ml-0 sm:mt-3 underline text-sm text-red-800 hover:text-red-900 italic sm:text-center'>Go back</Link>
          </div>
        </header>

        <main className='bg-portal-gray min-h-screen'>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

            <div className="px-4 py-6 sm:px-0">
              <Paperform formID={form.paperformHire} />
            </div>

          </div>
        </main>
      </>
    );
  }
}

export default withFirebase(ComItemNewHire);