import React from "react"
import { graphql } from "gatsby"
import { compose } from 'recompose';

import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import {
  withAuthorization,
} from '../components/Session';
import ComItemNewHire from "../components/Companies/ComItemNewHire";

export const newHireFormPageQuery = graphql`
  query newHireFormPageTemplateQuery($id: String!) {
    forms(id: { eq: $id }) {
      id
      company
      paperformHire
      paperformTerm
    }
  }
`

const NewHireFormPageBase = ({ form }) => (
  <div>
    <Navigation />

    <ComItemNewHire form={form} />
  </div>
)

const condition = authUser => !!authUser;

const NewHireFormPage = compose(
  withAuthorization(condition),
)(NewHireFormPageBase);

const NewHireFormPageTemplate = ({ data }) => {
  return (
    <Layout>
      <NewHireFormPage form={data.forms} />
    </Layout>
  )
}

export default NewHireFormPageTemplate